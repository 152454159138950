import { BodyText, Heading } from 'components/atoms/typography';
import React, { useEffect, useRef, useState } from 'react';

import { AppEventName } from 'lib/events/contracts';
import { AppPageProps } from 'gatsby/types';
import { Button } from 'components/atoms/button/Button';
import { Container } from 'components/atoms/layout/Container';
import ErrorMessage from 'components/molecules/actions/PageErrorMessage';
import PageContentLayout from 'components/layouts/PageContentLayout';
import PageLoading from 'components/molecules/actions/PageLoading';
import RateLimitInfo from 'components/molecules/forms/RateLimitInfo';
import { assertUnreachable } from 'lib/util';
import { loggers } from 'lib/log';
import { navigate } from 'gatsby';
import useAppEventDispatcher from 'lib/events/hooks';
import useBroadcastChannel from 'lib/hooks/useBroadcastChannel';
import { useQueryParams } from 'lib/navigation/hooks';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';
import { useMutation } from '@svelte/reactify/useQuery';
import { useSession } from 'service/hooks/session';

const log = loggers.ui;

export { Head } from 'components/atoms/Head';
export type Props = AppPageProps;

const enum ReissuedReason {
  EXPIRED,
  ALREADY_USED
}

type ReissuedMessageProps = {
  reason: ReissuedReason;
};

function reissuedMessage(reason: ReissuedReason) {
  switch (reason) {
    case ReissuedReason.EXPIRED:
      return 'The link you just used expired.';
    case ReissuedReason.ALREADY_USED:
      return 'This link was used once before.';
    default:
      assertUnreachable(reason);
  }
}

const RateLimited: React.FC<{ rateLimitRemaining: number }> = ({
  rateLimitRemaining
}) => {
  if (rateLimitRemaining) {
    return (
      <>
        <Heading level={1}>Please wait</Heading>
        <RateLimitInfo
          className="w-[580px]"
          message="A link was sent to your inbox less than a minute ago. Please wait, and then reload this page."
          rateLimitRemaining={rateLimitRemaining}
        />
      </>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <Heading level={1}>Link Already Used</Heading>
      <BodyText measure="measure-narrow" className="text-center">
        Please retry to get a new login link sent to your email inbox.
      </BodyText>
      <Button onClick={() => window.location.reload()}>Retry</Button>
    </div>
  );
};

const ReissuedMessage: React.FC<ReissuedMessageProps> = ({ reason }) => {
  const messages = [
    reissuedMessage(reason),
    "But it's ok. A brand new one is already on its way to your inbox. Please use that one instead."
  ];
  return (
    <>
      <Heading level={1}>Nearly there!</Heading>
      <BodyText measure="measure-narrow" className="text-center">
        {messages.join(' ')}
      </BodyText>
    </>
  );
};

const enum OpState {
  INVALID_TOKENS,
  LOADING,
  SENT,
  RATE_LIMITED,
  SUCCESS,
  REISSUED_EXPIRED,
  REISSUED_ALREADY_USED,
  ERROR
}

const ContentOfConfirmState: React.FC<{
  state: OpState;
  rateLimitRemaining: number;
}> = ({ rateLimitRemaining, state }) => {
  switch (state) {
    case OpState.INVALID_TOKENS:
      return (
        <ErrorMessage message="Some information is missing in order to show this page correctly. Please contact us for more info." />
      );
    case OpState.LOADING:
      return <PageLoading message="Loading" />;
    case OpState.SENT:
      return <PageLoading message="Logging you in" />;
    case OpState.REISSUED_ALREADY_USED:
      return <ReissuedMessage reason={ReissuedReason.ALREADY_USED} />;
    case OpState.RATE_LIMITED:
      return <RateLimited rateLimitRemaining={rateLimitRemaining} />;
    case OpState.REISSUED_EXPIRED:
      return <ReissuedMessage reason={ReissuedReason.EXPIRED} />;
    case OpState.SUCCESS:
      return <Heading level={1}>Done!</Heading>;
    case OpState.ERROR:
      return (
        <ErrorMessage message="We're unable to log you in at the moment. Please try again later." />
      );
    default:
      assertUnreachable(state);
  }
};

const MagicLinkActionsPage: React.FC<Props> = ({ pageContext }) => {
  const [opState, setOpState] = useState(OpState.LOADING);
  const token = useQueryParams('token');
  const tokenUid = useQueryParams('uid');
  const tokenRedirectPath = useQueryParams('redirect_path');
  const loginChannel = useBroadcastChannel('login');
  const [retryDelay, setRetryDelay] = useState(0);
  const hasRun = useRef(false);
  const uidTokenMaterialized = useRef(tokenUid);
  const worker = useGraphqlWorker();
  const session = useSession();
  const {
    data: requestData,
    error: requestError,
    mutate: exchangeMagicLink
  } = useMutation({
    key: 'exchangeLoginMagicLink',
    worker
  });

  const eventDispatcher = useAppEventDispatcher();

  useEffect(() => {
    /**
     * Wait until:
     * - state has registered query params
     * - session is authenticated
     */
    if (session.data) {
      const { id: uid, profile: user } = session.data.currentUser;
      /**
       * Don't re-run action
       * if already run during this lifecycle.
       *
       * This guard is required for when navigating away, as tokenUid may be undefined
       */
      if (hasRun.current) {
        return;
      }

      if (tokenUid) {
        uidTokenMaterialized.current = tokenUid;
      }

      /**
       * User is already logged in
       */
      if (uid === uidTokenMaterialized.current && !!user) {
        eventDispatcher.dispatch(AppEventName.ReLoggedIn);
        setOpState(OpState.SUCCESS);
        tokenRedirectPath && navigate(tokenRedirectPath);
        return;
      }

      if (!token || !tokenUid || !tokenRedirectPath) {
        setOpState(OpState.INVALID_TOKENS);
        loggers.ui.error(
          new Error('Missing query parameters on MagicLinkActionsPage'),
          { token, tokenUid, tokenRedirectPath }
        );
        return setOpState(OpState.ERROR);
      }

      exchangeMagicLink({
        token,
        uid: tokenUid,
        redirectPath: tokenRedirectPath
      });
      setOpState(OpState.SENT);
      hasRun.current = true;
    }
  }, [session.data, token, tokenUid, tokenRedirectPath]);

  useEffect(() => {
    const result = requestData?.exchangeMagicLink;

    switch (result?.__typename) {
      case 'MagicLinkExchange':
        setOpState(OpState.SUCCESS);
        const { redirect } = result;
        navigate(redirect);
        // NOTE: see in LogIn why this delay
        setTimeout(() => {
          loginChannel?.postMessage(true);
        }, 1000);
        break;
      case 'TokenResultFailure':
        const { reason } = result;

        switch (reason) {
          case 'ALREADY_USED':
            setOpState(OpState.REISSUED_ALREADY_USED);
            break;
          case 'EXPIRED':
            setOpState(OpState.REISSUED_EXPIRED);
            break;
          case 'UNSPECIFIED':
            log.error(
              new Error(
                'Unexpected Unspecified for MagicLinkReissueReason. Assuming expired'
              )
            );
            setOpState(OpState.REISSUED_EXPIRED);
            break;
          default:
            assertUnreachable(reason);
        }
        break;
      default:
    }
  }, [requestData]);

  useEffect(() => {
    if (requestError?.disruptorExtensions.retryDelayRemaining) {
      requestError.disruptorExtensions.setRetryCallback(n => {
        setRetryDelay(n);
        // set state async as otherwise there is a FOC
        // of up to 1 second
        // until callback is called
        if (opState !== OpState.RATE_LIMITED) {
          setOpState(OpState.RATE_LIMITED);
        }
      });
    } else if (requestError) {
      log.error(new Error('Unable to exchange magic link'), { requestError });
      setOpState(OpState.ERROR);
    }
  }, [requestError]);

  return (
    <PageContentLayout
      footerNavigation={pageContext.footerNavigation}
      withSubscriptionBanner={false}
      className="flex flex-col items-center justify-center"
    >
      <Container className="text-center">
        <ContentOfConfirmState
          state={opState}
          rateLimitRemaining={retryDelay}
        />
      </Container>
    </PageContentLayout>
  );
};

export default MagicLinkActionsPage;
